import React from "react";
import { FaLinkedin, FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8 px-6 w-full">
      <div className="container mx-auto grid grid-cols-3 gap-8">
        {/* First column for Privacy Policy */}
        <div className="flex flex-col">
          <h1 className="text-blue-300 text-lg font-semibold mb-4">
            Privacy Policy
          </h1>
          <Link to="contact-us">
            <h1 className="text-blue-300 text-lg font-semibold mb-4">
              Contact Us
            </h1>
          </Link>
          <h1 className="text-blue-300 text-lg font-semibold mb-4">
            Join our Team
          </h1>
        </div>

        {/* Second column for Address */}
        <div className="flex flex-col">
          <p className="text-lg text-blue-300">ADDRESS</p>
          <p className="text-sm mb-2">
            STEP, Thapar Institute of Engineering and Technology, Patiala,
            Punjab
          </p>
          <p className="text-sm mb-2">Mob: +91-7978272488 / 9778516365</p>
          <p className="text-sm">E-Mail: support@assignover.com</p>
        </div>

        {/* Third column for Social Media Icons */}
        <div className="flex flex-col items-center">
          <p className="text-lg font-semibold mb-4 text-blue-300">FOLLOW US</p>
          <div className="flex items-center">
            <a
              href="https://www.linkedin.com/company/assign-over/"
              className="mr-4"
            >
              <FaLinkedin className="text-white bg-transparent border-none text-6xl" />
            </a>
            <a href="https://www.facebook.com/assignover" className="mr-4">
              <FaFacebook className="text-white bg-transparent border-none text-6xl" />
            </a>
            <a href="https://www.instagram.com/assignover/">
              <FaInstagram className="text-white bg-transparent border-none text-6xl" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
