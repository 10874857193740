import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faClock,
  faLock,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./WhyUs.scss";
import whyus from "../../assets/whyus.png";

const WhyUs = () => {
  return (
    <div
      className="featured m-8 md:m-20 bg-transparent flex-wrap md:flex-nowrap mobile"
      style={{ paddingTop: "20px", paddingBottom: "20px" }}
    >
      <div className="w-full md:w-3/5 shadow-lg rounded-tl-3xl rounded-tr-none md:rounded-bl-3xl md:rounded-tl-3xl mb-4 md:mb-0 md:mr-4 hide-on-mobile">
        <img
          src={whyus}
          alt="Why Us"
          className="h-auto max-h-full rounded-tl-3xl md:rounded-none md:rounded-bl-3xl"
        />
      </div>

      <div className="w-full md:w-2/5 bg-gray-900 rounded-tr-3xl rounded-br-3xl flex flex-col justify-center px-8 py-6 mb-4 md:mb-0">
        <h1 className="text-logo-blue text-4xl mb-6">Why Choose Us?</h1>
        <div className="text-white">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon
              icon={faUsers}
              className="text-white bg-transparent border-0 mr-4"
            />
            <div>
              <h2 className="text-xl text-logo-purple">Expertise: </h2>
              <p className="mb-4">
                We connect you to the experts in your domain.
              </p>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <FontAwesomeIcon
              icon={faClock}
              className="text-white bg-transparent border-0 mr-4"
            />
            <div>
              <h2 className="text-xl text-logo-purple">Service Delivery: </h2>
              <p className="mb-4">
                We maintain a process centric approach to make your R & D work
                up to your expected Standard.
              </p>
            </div>
          </div>
          <div className="flex items-center  mb-4">
            <FontAwesomeIcon
              icon={faLock}
              className="text-white bg-transparent border-0 mr-4 "
            />
            <div>
              <h2 className="text-xl text-logo-purple">Confidentiality: </h2>
              <p>
                The identity of both Researchers and Clients are kept secret
                with the Management of AO.
              </p>
            </div>
          </div>
          <div className="flex items-center ">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-white bg-transparent border-0 mr-4"
            />
            <div>
              <h2 className="text-xl text-logo-purple">NDA: </h2>
              <p>
                Our experts are on NDA agreement with us to ensure the security
                & novelty of your R&D work.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
