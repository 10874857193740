import React from "react";
import Slider from "react-slick";

const CustomSlider = ({ children, onItemClick, ...props }) => {
  const handleClick = (index) => {
    if (onItemClick) {
      onItemClick(index);
    }
  };

  return (
    <Slider {...props}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          onClick: () => handleClick(index),
        })
      )}
    </Slider>
  );
};

export default CustomSlider;
