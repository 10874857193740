import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Featured.scss";
import researchimg from "../../assets/rsh.jpg";
import searchicon from "../../assets/sch.png";

const Featured = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (search) {
      navigate(`/research-gigs?search=${search}`);
    }
  };

  return (
    <div className="featured">
      <div className="container">
        <div className="left">
          <h1>
            <span>Search</span> and <span>Connect</span> With your
            <span className="spn2"> Domain</span> expert
          </h1>
          <div className="search">
            <div className="searchInput">
              <img src={searchicon} alt="search" />
              <input
                type="search"
                placeholder='Try "Research Writing"'
                onChange={({ target: { value } }) => setSearch(value)}
              />
            </div>
            <button className="search-btn" onClick={handleSearch}>
              Search
            </button>
          </div>
          <div className="popular">
            <span>Popular:</span>
            <button>Data Analysis</button>
            <button>Statistical Modeling</button>
            <button>Research Writing</button>
            <button>Experiment Design</button>
          </div>
        </div>

        <div className="right">
          <img src={researchimg} alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default Featured;
