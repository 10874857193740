import React, { useState } from "react";

const Enquiry = () => {
  // State variables for form inputs
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [superSubCategory, setSuperSubCategory] = useState("");
  const [filter, setFilter] = useState("");
  const [subFilter, setSubFilter] = useState("");
  const [description, setDescription] = useState("");

  // Data arrays
  const categories = ["Academic Research", "Market Research", "Lab Research"];

  const subCategories = {
    "Academic Research": ["Narrow AI (Week AI)"],
    "Market Research": [],
    "Lab Research": [],
  };

  const superSubCategories = {
    "Narrow AI (Week AI)": [
      "ML",
      "DL",
      "NLP",
      "Computer Vision",
      "Expert System",
      "Speech Recognition",
    ],
    "Market Research": [],
    "Lab Research": [],
  };

  const filters = ["Filter 1", "Filter 2", "Filter 3"];

  const subFilters = ["Sub Filter 1", "Sub Filter 2", "Sub Filter 3"];

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      category,
      subCategory,
      superSubCategory,
      filter,
      subFilter,
      description,
    });
  };

  return (
    <div className="bg-gray-100 bg-opacity-50 min-h-screen flex items-center justify-center m-8">
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg max-w-md">
        <h2 className="text-2xl font-bold text-logo-blue mb-4">Enquiry Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1 text-logo-blue">Category:</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 w-full"
            >
              <option value="">Select Category</option>
              {categories.map((cat, index) => (
                <option key={index} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-1 text-logo-blue">Sub Category:</label>
            <select
              value={subCategory}
              onChange={(e) => setSubCategory(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 w-full"
            >
              <option value="">Select Sub Category</option>
              {subCategories[category] &&
                subCategories[category].map((subCat, index) => (
                  <option key={index} value={subCat}>
                    {subCat}
                  </option>
                ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-1 text-logo-blue">
              Super Sub Category:
            </label>
            <select
              value={superSubCategory}
              onChange={(e) => setSuperSubCategory(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 w-full"
            >
              <option value="">Select Super Sub Category</option>
              {superSubCategories[subCategory] &&
                superSubCategories[subCategory].map((superSubCat, index) => (
                  <option key={index} value={superSubCat}>
                    {superSubCat}
                  </option>
                ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-1 text-logo-blue">Filter:</label>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 w-full"
            >
              <option value="">Select Filter</option>
              {filters.map((f, index) => (
                <option key={index} value={f}>
                  {f}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-1 text-logo-blue">Sub Filter:</label>
            <select
              value={subFilter}
              onChange={(e) => setSubFilter(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 w-full"
            >
              <option value="">Select Sub Filter</option>
              {subFilters.map((sf, index) => (
                <option key={index} value={sf}>
                  {sf}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-1 text-logo-blue">Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 w-full"
              rows="4"
              placeholder="Enter description"
            ></textarea>
          </div>

          {/* Submit button */}
          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Enquiry;
