import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import BrowseProjects from "./pages/BrowseProjects";
import Gigs from "../src/pages/Gigs/Gigs.jsx";
import Gig from "../src/pages/Gig/Gig.jsx";
import BecomeSeller from "./pages/BecomeSeller";
import Register from "./pages/Register/Register.jsx";
import "./App.css";
import Footer from "./components/Footer";
import Login from "./pages/Login/Login.jsx";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";
import { store } from "../src/redux/store.js";
import { QueryClient, QueryClientProvider } from "react-query";
import Profile from "./pages/Profile/Profile";
import BlogsPage from "./pages/BlogsPage/BlogsPage.jsx";
import Enquiry from "./components/Enquiry.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import Messages from "./pages/Messages/Messages.jsx";
import Message from "./pages/Message/Message.jsx";
const queryClient = new QueryClient();

const App = () => {
  return (
    <Provider store={store}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <div>
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/browse" element={<BrowseProjects />} />
              <Route path="/enquiry" element={<Enquiry />} />
              <Route path="/gigs" element={<Gigs />} />
              <Route path="/gig/:_id" element={<Gig />} />{" "}
              <Route path="/gig" element={<Gig />} />{" "}
              <Route path="/become-seller" element={<BecomeSeller />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />
              <Route exact path="/blogs" el ement={<BlogsPage />} />
              <Route path="/blog/:id" element={<BlogsPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/message/:id" element={<Message />} />
            </Routes>
            <Footer />
          </div>
        </QueryClientProvider>
      </RecoilRoot>
    </Provider>
  );
};

export default App;
