import React, { useState, useRef, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import GigCard from "../../components/GigCard/GigCard";
import "./Gigs.scss";
import { axiosFetch } from "../../utils";

const Gigs = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [sortBy, setSortBy] = useState("sales");
  const [category, setCategory] = useState(".");
  const minRef = useRef();
  const maxRef = useRef();
  const deliveryTimeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gigsData, setGigsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosFetch.get("/api/gigs/");
      setCategory(response.data.category);
      setGigsData(response.data);
      console.log(gigsData);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };
  const handlePriceFilter = async () => {
    const minPrice = minRef.current.value;
    const maxPrice = maxRef.current.value;
    setIsLoading(true);
    try {
      const response = await axiosFetch.get(
        `/api/gigs?min=${minPrice}&max=${maxPrice}&sort=${sortBy}`
      );
      setGigsData(response.data);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };
  const handleSortBy = (type) => {
    setSortBy(type);
    setOpenMenu(false);
  };

  const handleDeliveryTimeFilter = () => {
    const selectedDeliveryTime = deliveryTimeRef.current.value;
    // Add your filtering logic here
  };

  return (
    <div className="gigs">
      <div className="container">
        <p className="text-logo-purple">
          Explore the boundaries of art and technology with Assignover's{" "}
          {category} freelancers
        </p>
        <div className="menu">
          <div className="left">
            <span>Budget</span>
            <input ref={minRef} type="number" placeholder="min" />
            <input ref={maxRef} type="number" placeholder="max" />
            <button onClick={handlePriceFilter}>Apply</button>
          </div>
          <div className="right">
            <span className="sortBy">Sort By</span>
            <span className="sortType">
              {sortBy === "sales" ? "Best Selling" : "Newest"}
            </span>
            <img
              src="./media/down.png"
              alt=""
              onClick={() => setOpenMenu(!openMenu)}
            />
            {openMenu && (
              <div className="rightMenu">
                {sortBy === "sales" ? (
                  <span onClick={() => handleSortBy("createdAt")}>Newest</span>
                ) : (
                  <span onClick={() => handleSortBy("sales")}>
                    Best Selling
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="delivery-time-filter">
          <span>Delivery Time</span>
          <select ref={deliveryTimeRef}>
            <option value="">Any</option>
            <option value="1-day">1 Day</option>
            <option value="2-days">2 Days</option>
            <option value="3-days">3 Days</option>
          </select>
          <button onClick={handleDeliveryTimeFilter}>Apply</button>
        </div>
        <div className="cards">
          {isLoading ? (
            <div className="loader">
              <Loader size={45} />
            </div>
          ) : error ? (
            <div className="error-message">Something went wrong!</div>
          ) : (
            gigsData.map((gig) => <GigCard key={gig._id} data={gig} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default Gigs;
