import React from "react";
import { Link } from "react-router-dom";
import blogData from "../../pages/BlogsPage/blogData";

const Blogs = () => {
  return (
    <>
      <h1 className="text-logo-blue text-4xl font-bold text-center mt-12">
        From Our Blog
      </h1>
      <div className="flex flex-wrap justify-center mt-4">
        {blogData.map((blog, index) => (
          <Link key={index} to={`/blog/${index}`} className="m-4">
            <div className="max-w-xs rounded overflow-hidden shadow-lg bg-gray-900 text-white">
              <img
                className="w-full h-48 object-cover"
                src={blog.image}
                alt={blog.title}
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl h-14 overflow-hidden line-clamp-2">
                  {blog.title}
                </div>
                <hr className="my-2 w-full" />
                <p className="text-white text-base h-36 overflow-hidden line-clamp-6">
                  {blog.content}
                </p>
              </div>
              <div className="px-3 pt-4 pb-2">
                <div className="flex flex-wrap justify-start">
                  {blog.categories.map((category, i) => (
                    <span
                      key={i}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                    >
                      {category}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Blogs;
