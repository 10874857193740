import React, { useState } from "react";
import Modal from "react-modal";
import ProjectCard from "../components/ProjectCard";
import project1Image from "../assets/deeplearning1.jpg";
import project2Image from "../assets/deeplearning2.jpg";
import project3Image from "../assets/deeplearning3.jpg";
import { FaSearch, FaTimes } from "react-icons/fa";

const BrowseProjects = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = [
    {
      title: "Image Recognition with CNNs",
      category: "Deep Learning",
      subCategory: "Convolutional Neural Networks",
      description:
        "A project focused on image recognition using CNNs.A project focused on image recognition using CNNs.A project focused on image recognition using CNNs.A project focused on image recognition using CNNs.A project focused on image recognition using CNNs.",
      imageUrl: project1Image,
    },
    {
      title: "Sentiment Analysis with LSTM",
      category: "Natural Language Processing",
      subCategory: "Long Short-Term Memory Networks",
      description: "An NLP project for sentiment analysis using LSTM networks.",
      imageUrl: project2Image,
    },
    {
      title: "Stock Price Prediction with RNNs",
      category: "Machine Learning",
      subCategory: "Recurrent Neural Networks",
      description:
        "A machine learning project for predicting stock prices using RNNs.",
      imageUrl: project3Image,
    },
  ];

  const filteredProjects = projects.filter((project) =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openModal = (project) => {
    setSelectedProject(project);
    document.body.classList.add("overflow-hidden");
  };

  const closeModal = () => {
    setSelectedProject(null);
    document.body.classList.remove("overflow-hidden");
  };

  return (
    <div className="p-4 mt-10">
      <h1 className="text-4xl font-bold text-logo-blue text-center mb-8">
        Projects
      </h1>
      <div className="relative mb-6 mx-auto max-w-xl">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search projects"
          className="w-full py-2 pl-10 pr-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400"
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FaSearch className="text-logo-purple object-contain" />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {filteredProjects.map((project, index) => (
          <ProjectCard
            key={index}
            project={project}
            onDetailsClick={openModal}
          />
        ))}
      </div>
      <Modal
        isOpen={selectedProject !== null}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay fixed inset-0 bg-white bg-opacity-50 backdrop-blur-xs"
        style={{
          content: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "300px",
            maxHeight: "80%",
            overflow: "auto",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            padding: "20px",
            outline: "none",
          },
        }}
      >
        {selectedProject && (
          <div>
            <div className="flex justify-end">
              <button className="focus:outline-none" onClick={closeModal}>
                <FaTimes className="text-red-500  hover:text-red-800  outline-none shadow-none border-0  bg-transparent" />
              </button>
            </div>
            <img
              src={selectedProject.imageUrl}
              alt={selectedProject.title}
              className="w-full h-64 object-cover"
            />

            <h2 className="text-2xl font-bold mb-4">{selectedProject.title}</h2>
            <p className="text-gray-700">{selectedProject.description}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BrowseProjects;
