import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import "./Register.scss";
import registerbg from "../../assets/bg-login.jpg";
import { axiosFetch } from "../../utils";
import axios from "axios";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/snackbarSlice";
import { loginFailure, loginSuccess } from "../../redux/userSlice";
import { googleSignIn } from "../../api/index";
import { closeSignin } from "../../redux/setSigninSlice";

const Register = () => {
  const navigate = useNavigate();
  const [formInput, setFormInput] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
    description: "",
    isSeller: false,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosFetch.post("api/auth/register", {
        ...formInput,
      });
      if (response && response.data) {
        toast.success("Registration successful!");
        navigate("/login");
      } else {
        toast.error("Registration failed. Please try again.");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { value, name, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormInput({
      ...formInput,
      [name]: inputValue,
    });
  };

  const googleLoginSuccess = async (tokenResponse) => {
    setLoading(true);
    try {
      const user = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .catch((err) => {
          throw new Error(err.message);
        });

      googleSignIn({
        name: user.data.name,
        email: user.data.email,
        img: user.data.picture,
      }).then((res) => {
        if (res.status === 200) {
          dispatch(loginSuccess(res.data));
          dispatch(closeSignin());
          dispatch(
            openSnackbar({
              message: "Logged In Successfully",
              severity: "success",
            })
          );
          setLoading(false);
        } else {
          dispatch(loginFailure(res.data));
          dispatch(
            openSnackbar({
              message: res.data.message,
              severity: "error",
            })
          );
          setLoading(false);
        }
      });
    } catch (error) {
      dispatch(loginFailure());
      setLoading(false);
      dispatch(
        openSnackbar({
          message: error.message,
          severity: "error",
        })
      );
    }
  };

  const googleLoginError = (errorResponse) => {
    dispatch(loginFailure());
    setLoading(false);
    dispatch(
      openSnackbar({
        message: errorResponse.error,
        severity: "error",
      })
    );
  };

  return (
    <div className="register" style={{ backgroundImage: `url(${registerbg})` }}>
      <div className="form-container bg-white p-6 mx-auto mt-10 rounded-lg shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="left">
            <h1 className="text-3xl text-black">Create a new account</h1>
            <label>Username</label>
            <input
              name="username"
              type="text"
              placeholder="username"
              value={formInput.username}
              onChange={handleChange}
              className="h-10"
            />
            <label>Email</label>
            <input
              name="email"
              type="email"
              placeholder="email"
              value={formInput.email}
              onChange={handleChange}
              className="h-10"
            />
            <label>Password</label>
            <input
              name="password"
              type="password"
              value={formInput.password}
              onChange={handleChange}
              className="h-10"
            />

            <div className="flex justify-center">
              <p className="mt-5">
                Already have an account?{" "}
                <Link to="/login">
                  {" "}
                  <span className="text-blue-600 underline font-semibold hover:text-logo-purple">
                    Sign in
                  </span>
                </Link>
              </p>
            </div>
          </div>
          <div className="right">
            <label>Phone Number</label>
            <input
              name="phone"
              type="text"
              placeholder="+91 99999 99999"
              value={formInput.phone}
              onChange={handleChange}
            />
            <label>Description</label>
            <textarea
              placeholder="A short description of yourself"
              name="description"
              cols="30"
              rows="5"
              value={formInput.description}
              onChange={handleChange}
            ></textarea>
            <div className="flex justify-center">
              <button
                type="submit"
                className="btn h-10 w-32 rounded-md mt-5 hover:bg-logo-purple bg-logo-blue"
                disabled={loading}
              >
                {loading ? "Loading" : "Register"}
              </button>
            </div>
          </div>
        </form>
        <div className="flex justify-center mt-4">
          <GoogleOAuthProvider clientId="898637889369-rucfh8g3hvn8hg5de18veeutf75cjfp9.apps.googleusercontent.com">
            {" "}
            <GoogleLogin
              disabled={loading}
              type="button"
              className="btn max-w-52 flex items-center h-10 rounded-md bg-logo-purple hover:bg-gray-100 px-2"
              onSuccess={googleLoginSuccess}
              onError={googleLoginError}
            >
              <FontAwesomeIcon
                icon={faGoogle}
                className="border-0 bg-transparent -mr-3 shadow-none bg text-white"
              />
              Sign in with Google
            </GoogleLogin>
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );
};

export default Register;
