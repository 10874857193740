import { getCountryFlag } from "../../utils";
import "./Review.scss";
import starImg from "../../assets/star.jpeg";
import thumbsup from "../../assets/thumbsup.png";
import thumbsdown from "../../assets/thumbsdown.png";
const dummyReviews = [
  {
    userID: {
      username: "DeepLearner99",
      country: "United States",
      image:
        "https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745",
    },
    star: 5,
    description:
      "Absolutely phenomenal work! AOCSDL1 provided insightful analysis and helped refine my deep learning models. Highly recommended!",
  },
  {
    userID: {
      username: "NeuralNetGuru",
      country: "Canada",
      image:
        "https://www.shareicon.net/data/512x512/2015/09/18/103160_man_512x512.png",
    },
    star: 4,
    description:
      "AOCSDL1 is an expert in the field of deep learning. Their contributions greatly improved the performance of my neural network models.",
  },
];

const Review = (props) => {
  return (
    <div>
      <div className="text-2xl text-logo-blue">Reviews</div>

      {dummyReviews.map((review, index) => (
        <SingleReview key={index} review={review} />
      ))}
    </div>
  );
};

const SingleReview = ({ review }) => {
  const country = getCountryFlag(review?.userID?.country);

  return (
    <div className="review">
      <div className="user">
        <img
          className="pp"
          src={review.userID?.image || "/media/noavatar.png"}
          alt=""
        />
        <div className="info">
          <span className="username-review">{review?.userID?.username}</span>
          <div className="country">
            <img src={country?.normal} alt="" />
            <span className="country-name-review">
              {review?.userID?.country}
            </span>
          </div>
        </div>
      </div>
      <div className="stars">
        {new Array(review.star).fill(0).map((star, i) => (
          <img key={i} src={starImg} alt="" />
        ))}
        <span>{review.star}</span>
      </div>
      <p>{review.description}</p>
      <div className="helpful">
        <span>Helpful?</span>
        <img src={thumbsup} alt="" />
        <span>Yes</span>
        <img src={thumbsdown} alt="" />
        <span>No</span>
      </div>
    </div>
  );
};

export default Review;
