import React, { useState, useEffect } from "react";
import axios from "axios";
import PersonalInfo from "./PersonalInfo";
import ProfessionalInformation from "./ProfessionalInfo";
import AccountSecurity from "./AccountSecurity";
import WorkProfile from "./WorkProfile";
import ProgressBar from "react-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faCog,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { axiosFetch } from "../../utils";

const Profile = () => {
  const [selectedSection, setSelectedSection] = useState("personalInfo");
  const [data, setData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const _id = localStorage.getItem("id");
        const response = await axiosFetch.get("api/profile/" + _id);
        setProfileData(response.data.profile);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };

  const calculateProgress = () => {
    switch (selectedSection) {
      case "personalInfo":
        return 25;
      case "professionalInformation":
        return 50;
      case "workProfile":
        return 75;
      case "accountSecurity":
        return 100;
      default:
        return 0;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const _id = localStorage.getItem("id");

      let requestData = {};
      switch (selectedSection) {
        case "personalInfo":
          requestData = {
            section: "personalInfo",
            data: {
              firstName: data.firstName,
              Displayname: data.displayName,
              profilepic: data.profilePic,
              description: data.description,
              language: data.language,
              proficiency: data.proficiency,
            },
          };
          break;
        case "professionalInformation":
          requestData = {
            section: "professionalInformation",
            data: {
              eduactionlevel: data.educationLevel,
              year: data.year,
              college: data.college,
              university: data.university,
              majorsubject: data.majorSubject,
              occupation: data.occupation,
              workprofile: data.workProfile,
              bio: data.bio,
            },
          };
          break;
        case "workProfile":
          requestData = {
            section: "workProfile",
            data: {
              // Add fields specific to WorkProfile section
            },
          };
          break;
        case "accountSecurity":
          requestData = {
            section: "accountSecurity",
            data: {
              email: data.email,
              phoneno: data.phoneNo,
            },
          };
          break;
        default:
          throw new Error("Invalid section");
      }

      const response = await axios.put(
        `api/profile/update/` + _id,
        requestData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error updating profile section:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto mt-10 px-4">
      <div className="flex justify-center mb-6">
        <button
          className={`mr-4 px-4 py-2 rounded-md ${
            selectedSection === "personalInfo"
              ? "bg-logo-purple text-white"
              : "bg-logo-blue text-white"
          }`}
          style={{ width: "200px" }}
          onClick={() => handleSectionChange("personalInfo")}
        >
          <FontAwesomeIcon
            icon={faUser}
            className="mr-2 border-0 shadow-none outline-none bg-transparent text-white"
          />
          <span className="">Personal</span>
        </button>
        <button
          className={`mr-4 px-4 py-2 rounded-md ${
            selectedSection === "professionalInformation"
              ? "bg-logo-purple text-white"
              : "bg-logo-blue text-white"
          }`}
          style={{ width: "200px" }}
          onClick={() => handleSectionChange("professionalInformation")}
        >
          <FontAwesomeIcon
            icon={faLock}
            className="mr-2 border-0 shadow-none outline-none bg-transparent text-white"
          />
          <span className="">Professional</span>
        </button>
        <button
          className={`mr-4 px-4 py-2 rounded-md ${
            selectedSection === "workProfile"
              ? "bg-logo-purple text-white"
              : "bg-logo-blue text-white"
          }`}
          style={{ width: "200px" }}
          onClick={() => handleSectionChange("workProfile")}
        >
          <FontAwesomeIcon
            icon={faBriefcase}
            className="mr-2 border-0 shadow-none outline-none bg-transparent text-white"
          />
          <span className="">Work Profile</span>
        </button>
        <button
          className={`px-4 py-2 rounded-md ${
            selectedSection === "accountSecurity"
              ? "bg-logo-purple text-white"
              : "bg-logo-blue text-white"
          }`}
          style={{ width: "200px" }}
          onClick={() => handleSectionChange("accountSecurity")}
        >
          <FontAwesomeIcon
            icon={faCog}
            className="mr-2 border-0 shadow-none outline-none bg-transparent text-white"
          />
          <span className="">Account Security</span>
        </button>
      </div>

      <div className="flex justify-center mb-4">
        <ProgressBar
          completed={calculateProgress()}
          bgColor="#d6d6d6"
          height="20px"
        />
      </div>

      {selectedSection === "personalInfo" && (
        <PersonalInfo
          handleInputChange={handleInputChange}
          profileData={profileData}
        />
      )}
      {selectedSection === "professionalInformation" && (
        <ProfessionalInformation handleInputChange={handleInputChange} />
      )}
      {selectedSection === "workProfile" && (
        <WorkProfile handleInputChange={handleInputChange} />
      )}
      {selectedSection === "accountSecurity" && (
        <AccountSecurity
          handleInputChange={handleInputChange}
          profileData={profileData}
        />
      )}
    </div>
  );
};

export default Profile;
