import { useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import NextArrow from "./Arrows/NextArrow";
import PrevArrow from "./Arrows/PrevArrow1";
import CustomSlider from "./CustomSlider";
import { FaBars } from "react-icons/fa";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = false;
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const menuLinks = [
    {
      path: "/gigs?category=academicresearch",
      name: "Academic Research",
      subcategories: [
        { name: "Web Development", topics: ["HTML", "CSS", "JavaScript"] },
        {
          name: "Data Science",
          topics: ["Machine Learning", "Data Visualization"],
        },
      ],
    },
    {
      path: "/gigs?category=marketresearch",
      name: "Market Research",
      subcategories: [
        {
          name: "Organic Chemistry",
          topics: ["Organic Synthesis", "Reaction Mechanisms"],
        },
        {
          name: "Inorganic Chemistry",
          topics: ["Coordination Chemistry", "Solid-State Chemistry"],
        },
      ],
    },
    {
      path: "/gigs?category=labresearch",
      name: "Lab Research",
      subcategories: [
        {
          name: "Classical Mechanics",
          topics: ["Newton's Laws", "Kinematics"],
        },
        {
          name: "Quantum Mechanics",
          topics: ["Wave-Particle Duality", "Schrodinger Equation"],
        },
      ],
    },
    {
      path: "/gigs?category=processdevelopment",
      name: "Process Development",
      subcategories: [
        {
          name: "Classical Mechanics",
          topics: ["Newton's Laws", "Kinematics"],
        },
        {
          name: "Quantum Mechanics",
          topics: ["Wave-Particle Duality", "Schrodinger Equation"],
        },
      ],
    },
    {
      path: "/gigs?category=productdevelopment",
      name: "Product Development",
      subcategories: [
        {
          name: "Classical Mechanics",
          topics: ["Newton's Laws", "Kinematics"],
        },
        {
          name: "Quantum Mechanics",
          topics: ["Wave-Particle Duality", "Schrodinger Equation"],
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (index) => {
    setActiveCategory((prevIndex) => (prevIndex === index ? null : index));
    console.log("Clicked category index:", index);
    console.log("Active category index:", activeCategory);
  };

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <nav className="bg-custom-yellow p-4 bg-gray-800">
      <div className=" mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <div className="text-black mr-4 md:block hidden">
            <img src={logo} alt="logo" className="h-20" />
          </div>
          <div className="company-name text-white font-semibold">
            <h1 className="text-3xl ">ASSIGNOVER</h1>
            <h6 className="text-center ">
              Simplifying R&D&nbsp;<span> (Beta)</span>
            </h6>
          </div>
          <div className="flex text-center absolute right-4 object-contain">
            <button
              className="text-black focus:outline-none lg:hidden"
              onClick={toggleNavbar}
            >
              <FaBars className="text-white bg-transparent border-0" />
            </button>
          </div>
        </div>
        <div
          className={`lg:flex lg:items-center ${isOpen ? "block" : "hidden"}`}
        >
          <div className="text-white lg:flex lg:mx-auto font-semibold">
            <Link
              to="/"
              className="block lg:inline-block mt-4 lg:mt-0 text-white hover:text-black mr-4 hover:bg-logo-purple py-2 px-4 rounded transition duration-300"
            >
              Home
            </Link>
            <Link
              to="/browse"
              className="block lg:inline-block mt-4 lg:mt-0 text-white hover:text-black mr-4 hover:bg-logo-purple py-2 px-4 rounded transition duration-300"
            >
              Browse Projects
            </Link>
            <Link
              to="/enquiry"
              className="block lg:inline-block mt-4 lg:mt-0 text-white hover:text-black mr-4 hover:bg-logo-purple py-2 px-4 rounded transition duration-300"
            >
              Enquiry Form
            </Link>
            <Link
              to="/gigs"
              className="block lg:inline-block mt-4 lg:mt-0 text-white hover:text-black mr-4 hover:bg-logo-purple py-2 px-4 rounded transition duration-300"
            >
              Gigs
            </Link>
          </div>
          <div className="text-white lg:flex lg:mx-auto lg:mt-0 font-semibold">
            <Link
              to="/become-seller"
              className="block lg:inline-block rounded-lg lg:mt-0 mt-4 hover:text-black mr-4 hover:bg-logo-purple bg-logo-blue py-2 px-2  transition duration-300"
            >
              Become a Seller
            </Link>
            {isLoggedIn ? (
              <Link
                to="/profile"
                className="block lg:inline-block rounded-lg lg:mt-0 mt-4 hover:text-black hover:bg-logo-purple bg-logo-blue py-2 px-2 transition duration-300"
              >
                Profile
              </Link>
            ) : (
              <Link
                to="/login"
                className="block lg:inline-block rounded-lg lg:mt-0 mt-4 hover:text-black hover:bg-logo-purple bg-logo-blue py-2 px-2 transition duration-300"
              >
                Sign in
              </Link>
            )}
          </div>
        </div>
      </div>
      <hr className="w-full my-2" />
      <div className="text-light-color mx-16">
        <div className="max-w-[100vw] overflow-hidden">
          <CustomSlider {...settings}>
            {menuLinks.map(({ name, subcategories }, index) => (
              <div key={name} className="menu-item text-center">
                <div className="relative">
                  <Link className="link" to="#">
                    {name}
                  </Link>
                  {activeCategory === index && (
                    <div className="absolute left-0 top-full bg-white shadow-md rounded-md mt-2 py-2 px-4">
                      {subcategories.map(({ name: subcategory, topics }) => (
                        <div key={subcategory}>
                          <h3 className="font-semibold">{subcategory}</h3>
                          <ul>
                            {topics.map((topic) => (
                              <li key={topic}>{topic}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </CustomSlider>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
