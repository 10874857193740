import React from "react";
import { useParams } from "react-router-dom";
import blogData from "../../pages/BlogsPage/blogData";

const BlogPage = () => {
  const { id } = useParams();
  const blogIndex = parseInt(id);

  if (isNaN(blogIndex) || blogIndex < 0 || blogIndex >= blogData.length) {
    return <div className="text-center text-red-500 mt-8">Blog not found</div>;
  }

  const blog = blogData[blogIndex];

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-12">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
          <img className="w-full h-auto" src={blog.image} alt={blog.title} />
          <div className="p-8">
            <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>
            <p className="text-gray-500 mb-4">{blog.date}</p>
            <div
              className="text-lg leading-relaxed blog-content prose"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
