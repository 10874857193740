import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const ProjectCard = ({ project, onDetailsClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [descriptionInput, setDescriptionInput] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [otherRequests, setOtherRequests] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDescriptionChange = (event) => {
    setDescriptionInput(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleOtherRequestsChange = (event) => {
    setOtherRequests(event.target.value);
  };

  const handleGetQuoteClick = () => {
    openModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    closeModal();
  };

  const { title, description, imageUrl, category, subCategory } = project;

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-64 object-cover" />
      <div className="p-4">
        <h2 className="text-xl font-bold text-logo-blue mb-2 truncate">
          {title}
        </h2>
        <p className="text-gray-700 h-12 mb-4 overflow-hidden overflow-ellipsis">
          {description}
        </p>
        <div className="flex justify-center flex-row text-white space-x-2">
          <button
            className="bg-logo-blue p-2 rounded-lg"
            onClick={() => onDetailsClick(project)}
          >
            Show More
          </button>
          <Link to="/messages">
            <button className="bg-logo-blue p-2 rounded-lg">Chat</button>
          </Link>
          <button
            className="bg-logo-blue p-2 rounded-lg"
            onClick={handleGetQuoteClick}
          >
            Get Quote
          </button>{" "}
        </div>
        <div className="flex mt-4 justify-center">
          <span className="inline-block text-center bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
            {category}
          </span>
          <span className="inline-block bg-gray-200 text-center rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
            {subCategory}
          </span>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay fixed inset-0 bg-white bg-opacity-50 backdrop-blur-xs"
        style={{
          content: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "400px",
            maxWidth: "80%",
            maxHeight: "80%",
            overflow: "auto",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            padding: "20px",
            outline: "none",
          },
        }}
      >
        <div className="pl-4">
          <div className="flex justify-end mb-4">
            <button
              className="focus:outline-none absolute pt-4 "
              onClick={closeModal}
            >
              <FaTimes className="text-red-500 border-0 outline-none shadow-none " />
            </button>
          </div>
          <h2 className="text-xl font-bold text-logo-blue mb-2 truncate">
            {title}
          </h2>
          <p className="text-gray-700 h-12 mb-4 overflow-hidden overflow-ellipsis">
            {description}
          </p>
          <h2 className="text-xl font-bold mb-4 text-logo-blue">
            Request a Quote
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block mb-2 text-logo-blue"
              >
                Description:
              </label>
              <textarea
                id="description"
                value={descriptionInput}
                onChange={handleDescriptionChange}
                className="w-full border border-gray-300 rounded-lg p-2"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="date" className="block mb-2 text-logo-blue">
                Delivery Date:
              </label>
              <select
                id="date"
                value={selectedDate}
                onChange={handleDateChange}
                className="w-full border border-gray-300 rounded-lg p-2"
              >
                <option value="">Select Delivery Date</option>
                <option value="7">7 days</option>
                <option value="14">14 days</option>
                <option value="21">21 days</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="plan" className="block mb-2 text-logo-blue">
                Plan Type:
              </label>
              <select
                id="plan"
                value={selectedPlan}
                onChange={handlePlanChange}
                className="w-full border border-gray-300 rounded-lg p-2"
              >
                <option value="">Select Plan Type</option>
                <option value="basic">Basic</option>
                <option value="standard">Standard</option>
                <option value="premium">Premium</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="otherRequests"
                className="block mb-2 text-logo-blue"
              >
                Other Requests:
              </label>
              <textarea
                id="otherRequests"
                value={otherRequests}
                onChange={handleOtherRequestsChange}
                className="w-full border border-gray-300 rounded-lg p-2"
              />
            </div>
            <button
              type="submit"
              className="bg-logo-blue text-white py-2 px-4 rounded-lg"
            >
              Submit
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectCard;
