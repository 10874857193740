import React from "react";
import wos from "../assets/journals/wos.png";
import Scopus from "../assets/journals/Scopus.png";
import ugc from "../assets/journals/ugc.png";
import abdc from "../assets/journals/abdc.jpeg";
import bgtrusted from "../assets/bg-trusted.jpg";
import lightbg from "../assets/light-background.png";

const TrustedBy = () => {
  return (
    <div
      className="bg-gray-100 flex justify-center p-5"
      style={{
        backgroundImage: `url(${lightbg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-full max-w-4xl flex flex-col md:flex-row items-center justify-center gap-4 md:gap-6 text-black font-semibold">
        <span className="hidden md:inline text-lg md:text-xl whitespace-normal md:whitespace-nowrap break-words">
          Meeting the Standards of:
        </span>

        <img
          className="h-20 md:h-24 object-contain bg-transparent"
          src={wos}
          alt="Web Of Science"
        />
        <img
          className="h-20 md:h-24 object-contain bg-transparent"
          src={Scopus}
          alt="Scopus"
        />
        <img
          className="h-20 md:h-24 object-contain bg-transparent"
          src={ugc}
          alt="UGC"
        />
        <img
          className="h-16 md:h-20 object-contain bg-transparent"
          src={abdc}
          alt="AbdC"
        />
      </div>
    </div>
  );
};

export default TrustedBy;
