import React, { useState, useEffect } from "react";
import { axiosFetch } from "../../utils";
import axios from "axios";


const AccountSecurity = () => {
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchProfileData = async (request, response) => {
      try {
        const _id = localStorage.getItem("id");
        console.log(_id);
        const response = await axiosFetch.get("api/profile/" + _id);
        setProfileData(response.data.profile);
        console.log(profileData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);
  const [selectedSection, setSelectedSection] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [profileEmail, setProfileEmail] = useState(profileData.email);
  const [profilePhone, setProfilePhone] = useState(profileData.phoneNumber);

  const handleEdit = () => {
    setEditMode(true);
  };

  const [data, setData] = useState({});

  const handleAccountSecuritySubmit = async (e) => {
    e.preventDefault();
    try {
      const _id = localStorage.getItem("id");
  
      const requestData = {
        section: "accountSecurity",
        data: {
          email: data.email,
          phoneno: data.phoneNo,
        },
      };
  
      const response = await axios.put(`api/profile/update/${_id}`, requestData);
      console.log(response.data);
    } catch (error) {
      console.error("Error updating account security:", error);
    }
  };

  const handleEmailChange = (e) => {
    setProfileEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setProfilePhone(e.target.value);
  };

  return (
    <div className="flex justify-center items-center h-auto m-10">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl text-logo-blue mb-4">Account Security</h2>
        {/* Email */}
        <div className="mb-4">
          <label htmlFor="email" className="text-logo-blue mb-2 block">
            E-Mail
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={profileEmail}
            onChange={handleEmailChange}
            placeholder="E-Mail"
            className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
            disabled={!editMode}
          />
        </div>
        {/* Phone Number */}
        <div className="mb-4">
          <label htmlFor="phoneNumber" className="text-logo-blue mb-2 block">
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={profilePhone}
            onChange={handlePhoneChange}
            placeholder="Phone Number"
            className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
            disabled={!editMode} // Disable input when not in edit mode
          />
        </div>
        {/* Edit/Save button */}
        {!editMode ? (
          <button
            onClick={handleEdit}
            className="bg-logo-blue text-white px-4 py-2 rounded-md"
          >
            Edit
          </button>
        ) : (
          <button
            onClick={handleAccountSecuritySubmit}
            className="bg-logo-blue text-white px-4 py-2 rounded-md"
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default AccountSecurity;
