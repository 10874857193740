import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import NextArrow from "../../components/Arrows/NextArrow";
import PrevArrow from "../../components/Arrows/PrevArrow1";
import Review from "../../components/Review/Review";
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./Gig.scss";
import revIcon from "../../assets/revisionIcon.png";
import delIcon from "../../assets/delivery.png";

const Gig = () => {
  const { _id } = useParams();
  const [gigData, setGigData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGigData = async () => {
      try {
        const response = await fetch(`/api/gigs//single/${_id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch gig data");
        }
        const data = await response.json();
        setGigData(data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchGigData();
  }, [_id]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const dummyReviewData = {
    review: {
      userID: {
        username: "JohnDoe",
        country: "United States",
        image:
          "https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745",
      },
      star: 4,
      description: "This is a dummy review. It's just for testing purposes.",
    },
  };

  return (
    <div className="gig">
      <div className="container">
        <div className="left">
          <span className="breadcrumbs text-logo-purple font-semibold">
            {gigData.category}
          </span>
          <h1 className="text-3xl">{gigData.title}</h1>
          <div className="user">
            <img
              className="pp"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYoCpYbnYiPFSJAw9ozabbMr7fucuIqlNLmDCUFxi0ZU8WFceA2hx28kZncPI2ZplvNx0&usqp=CAU"
              alt=""
            />
            <span>{gigData.userID.username}</span>
          </div>

          <CarouselProvider
            naturalSlideHeight={100}
            naturalSlideWidth={125}
            totalSlides={gigData.images.length}
            infinite
            className="slider"
          >
            <Slider>
              {gigData.images.map((image, index) => (
                <Slide key={index}>
                  <Image
                    src="https://res.cloudinary.com/dxdmsmtgn/image/upload/lriq9pkvxfnn1jy15i3k.jpg"
                    alt={image.alt}
                  />
                </Slide>
              ))}
            </Slider>
            {/* <Slider>
              {gigData.images.map((image, index) => (
                <Slide key={index}>
                  <Image src={image.path} alt={image.alt} />
                </Slide>
              ))}
            </Slider> */}

            <ButtonBack>
              <PrevArrow />
            </ButtonBack>

            <ButtonNext>
              <NextArrow />
            </ButtonNext>
          </CarouselProvider>

          <div className="right-mobile right">
            <div className="price">
              <h3>{gigData.shortTitle}</h3>
              <h2>
                {gigData.price.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: "INR",
                })}
              </h2>
            </div>
            <p>{gigData.shortDesc}</p>
            <div className="details">
              <div className="item">
                <img src={delIcon} alt="" />
                <span>{gigData.deliveryTime} days Delivery</span>
              </div>
              <div className="item">
                <img src={revIcon} alt="" />
                <span>{gigData.revisionNumber} Revisions</span>
              </div>
            </div>
            <Link to={`/pay/${_id}`}>
              <button>Continue</button>
            </Link>
          </div>
          <h2 className="text-2xl text-logo-blue">About This Gig</h2>
          <p className="text-lg">{gigData.description}</p>
          <div className="seller">
            <h2 className="text-2xl text-logo-blue">About The Seller</h2>
            <div className="user">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYoCpYbnYiPFSJAw9ozabbMr7fucuIqlNLmDCUFxi0ZU8WFceA2hx28kZncPI2ZplvNx0&usqp=CAU"
                alt=""
              />
              <div className="info">
                <span>{gigData.userID.username}</span>
                <p>{gigData.information}</p>
              </div>
              {/* <div className="previous">
                <h1>Previous Work</h1>
              </div> */}
            </div>
          </div>
          <Review review={dummyReviewData.review} />
        </div>
      </div>
    </div>
  );
};

export default Gig;
