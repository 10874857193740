import Select from "react-select";
import { axiosFetch } from "../../utils";
import axios from "axios";
import React, { useState, useEffect } from "react";


const ProfessionalInformation = () => {
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchProfileData = async (request, response) => {
      try {
        const _id = localStorage.getItem("id");
        console.log(_id);
        const response = await axiosFetch.get("api/profile/" + _id);
        setProfileData(response.data.profile);
        console.log(profileData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);
  
  const [selectedSection, setSelectedSection] = useState("");
  const [professionalInformation, setProfessionalInformation] = useState({
    education: [
      {
        degree: "",
        year: "",
        college: "",
        university: "",
        major: "",
      },
    ],
    occupation: [],
    projects: [
      {
        category: "",
        subCategory: "",
        superSubCategory: "",
        experience: "",
        number: "",
      },
    ],
    skills: [
      {
        skill: "",
        level: "",
        yearOfLearning: "",
        experience: "",
      },
    ],
  });

  const handleAddEducation = () => {
    setProfessionalInformation({
      ...professionalInformation,
      education: [
        ...professionalInformation.education,
        {
          degree: "",
          year: "",
          college: "",
          university: "",
          major: "",
        },
      ],
    });
  };

  const handleEducationChange = (index, field, value) => {
    const updatedEducation = [...professionalInformation.education];
    updatedEducation[index][field] = value;
    setProfessionalInformation({
      ...professionalInformation,
      education: updatedEducation,
    });
  };

  const handleEducationOther = (index, value) => {
    const updatedEducation = [...professionalInformation.education];
    updatedEducation[index].degree = value;
    setProfessionalInformation({
      ...professionalInformation,
      education: updatedEducation,
    });
  };
  const [data, setData] = useState({});

  const handleProfessionalInfoSubmit = async (e) => {
    e.preventDefault();
    try {
      const _id = localStorage.getItem("id");
  
      const requestData = {
        section: "professionalInformation",
        data: {
          eduactionlevel: data.educationLevel,
          year: data.year,
          college: data.college,
          university: data.university,
          majorsubject: data.majorSubject,
          occupation: data.occupation,
          workprofile: data.workProfile,
          bio: data.bio,
        },
      };
  
      const response = await axios.put(`api/profile/update/${_id}`, requestData);
      console.log(response.data);
    } catch (error) {
      console.error("Error updating professional info:", error);
    }
  };

  const handleOccupationChange = (selectedOptions) => {
    setProfessionalInformation({
      ...professionalInformation,
      occupation: selectedOptions.map((option) => option.value),
    });
  };

  const occupationOptions = [
    { value: "Student", label: "Student" },
    { value: "Researcher", label: "Researcher" },
    { value: "Academician", label: "Academician" },
  ];

  return (
    <div className="flex justify-center items-center m-10">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl text-logo-blue mb-4">
          Professional Information
        </h2>
        {/* Education */}
        {professionalInformation.education.map((edu, index) => (
          <div key={index} className="mb-4">
            {/* Degree */}
            <div className="mb-2">
              <label
                htmlFor={`degree-${index}`}
                className="text-logo-blue mb-2 block"
              >
                Education Level
              </label>
              <select
                id={`degree-${index}`}
                value={edu.degree}
                onChange={(e) =>
                  handleEducationChange(index, "degree", e.target.value)
                }
                className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
              >
                <option value="">Select Education Level</option>
                <option value="Diploma">Diploma</option>
                <option value="Bachelor">Bachelor</option>
                <option value="Master">Master</option>
                <option value="PhD">PhD</option>
                <option value="Post.Doc.">Post.Doc.</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {/* Other Degree Input */}
            {edu.degree === "Other" && (
              <div className="mb-2">
                <input
                  type="text"
                  value={edu.degree}
                  onChange={(e) => handleEducationOther(index, e.target.value)}
                  placeholder="Enter Other Education Level"
                  className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
                />
              </div>
            )}
            {/* Other Education Fields */}
            {edu.degree !== "Other" && (
              <>
                {/* Year */}
                <div className="mb-2">
                  <label
                    htmlFor={`year-${index}`}
                    className="text-logo-blue mb-2 block"
                  >
                    Year
                  </label>
                  <input
                    type="text"
                    id={`year-${index}`}
                    value={edu.year}
                    onChange={(e) =>
                      handleEducationChange(index, "year", e.target.value)
                    }
                    placeholder="Year"
                    className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
                  />
                </div>
                {/* College */}
                <div className="mb-2">
                  <label
                    htmlFor={`college-${index}`}
                    className="text-logo-blue mb-2 block"
                  >
                    College
                  </label>
                  <input
                    type="text"
                    id={`college-${index}`}
                    value={edu.college}
                    onChange={(e) =>
                      handleEducationChange(index, "college", e.target.value)
                    }
                    placeholder="College"
                    className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
                  />
                </div>
                {/* University */}
                <div className="mb-2">
                  <label
                    htmlFor={`university-${index}`}
                    className="text-logo-blue mb-2 block"
                  >
                    University
                  </label>
                  <input
                    type="text"
                    id={`university-${index}`}
                    value={edu.university}
                    onChange={(e) =>
                      handleEducationChange(index, "university", e.target.value)
                    }
                    placeholder="University"
                    className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
                  />
                </div>
                {/* Major Subject */}
                <div className="mb-2">
                  <label
                    htmlFor={`major-${index}`}
                    className="text-logo-blue mb-2 block"
                  >
                    Major Subject
                  </label>
                  <input
                    type="text"
                    id={`major-${index}`}
                    value={edu.major}
                    onChange={(e) =>
                      handleEducationChange(index, "major", e.target.value)
                    }
                    placeholder="Major Subject"
                    className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
                  />
                </div>
              </>
            )}
          </div>
        ))}
        {/* Add Education Button */}
        {/* <button
          onClick={handleAddEducation}
          className="bg-logo-blue text-white px-4 py-2 rounded-md"
        >
          Add Education
        </button> */}

        {/* Occupation */}
        <div className="mb-4">
          <label htmlFor="occupation" className="text-logo-blue mb-2 block">
            Occupation
          </label>
          <Select
            id="occupation"
            isMulti
            options={occupationOptions}
            value={occupationOptions.filter((option) =>
              professionalInformation.occupation.includes(option.value)
            )}
            onChange={handleOccupationChange}
            className="w-full"
          />
        </div>
        <label htmlFor="myFile" className="text-logo-blue mb-2 block">
          Work Profile
        </label>
        <input type="file" id="myFile" name="workprofile"></input>
        <div className="flex justify-center mt-5">
          <button onClick={handleProfessionalInfoSubmit} className="p-2 text-white rounded-lg bg-logo-blue">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalInformation;
