import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useRecoilState } from "recoil";
import { userState } from "../../atoms";
import { useDispatch } from "react-redux";
import validator from "validator";
import { openSnackbar } from "../../redux/snackbarSlice";
import { loginFailure, loginSuccess } from "../../redux/userSlice";
import { closeSignin } from "../../redux/setSigninSlice";
import { axiosFetch } from "../../utils";
import axios from "axios";
import "./Login.scss";
import loginbg from "../../assets/bg-login.jpg";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { googleSignIn } from "../../api/index";

const initialState = {
  username: "",
  password: "",
};

const Login = () => {
  const [formInput, setFormInput] = React.useState(initialState);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const dispatch = useDispatch();

  const handleFormInput = (event) => {
    const { value, name } = event.target;
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosFetch.post("api/auth/login", {
        ...formInput,
      });
      if (response && response.data) {
        console.log(response.data)
          localStorage.setItem('_\id', response.data.user._id);
        
        toast.success("login successful!");
        navigate("/profile");
      } else {
        toast.error("login failed. Please try again.");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  

  const googleLoginSuccess = async (token) => {
    setLoading(true);
    try {
      const accessToken = token.accesstoken;
      const response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
  
      const userData = response.data;
  
      // Assuming googleSignIn is an asynchronous function
      const res = await googleSignIn({
        name: userData.name,
        email: userData.email,
        img: userData.picture,
      });
  
      if (res.status === 200) {
        dispatch(loginSuccess(res.data));
        dispatch(closeSignin());
        dispatch(
          openSnackbar({
            message: "Logged In Successfully",
            severity: "success",
          })
        );
      } else {
        dispatch(loginFailure(res.data));
        dispatch(
          openSnackbar({
            message: res.data.message,
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      dispatch(loginFailure());
      dispatch(
        openSnackbar({
          message: "Failed to log in with Google",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  
  const googleLoginError = (errorResponse) => {
    dispatch(loginFailure());
    setLoading(false);
    dispatch(
      openSnackbar({
        message: errorResponse.error,
        severity: "error",
      })
    );
  };

  return (
    <div className="login" style={{ backgroundImage: `url(${loginbg})` }}>
    <div className="bg-white my-16 p-6 rounded-lg shadow-md">
      <form onSubmit={handleFormSubmit}>
        <h1 className="text-4xl text-center mb-4 text-black font-semibold">
          Login
        </h1>
        <label htmlFor="username">Username</label>
        <input
          id="username"
          name="username"
          placeholder="Username"
          onChange={handleFormInput}
          className="h-12 w-full border border-gray-300 rounded-md px-4"
        />

        <label htmlFor="password" className="mt-4">
          Password
        </label>
        <input
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleFormInput}
          className="h-12 w-full border border-gray-300 rounded-md px-4"
        />
        <div className="flex justify-center mt-6">
          <button
            disabled={loading}
            type="submit"
            className="btn h-10 w-32 rounded-md hover:bg-logo-purple bg-logo-blue"
          >
            {loading ? "Loading" : "Login"}
          </button>
        </div>
        {error && <span className="text-red-500">{error}</span>}
        <div className="flex justify-center mt-4">
          <p>
            Don't have an account?{" "}
            <Link to="/register">
              <span className="text-blue-600 underline font-semibold hover:text-logo-purple">
                Sign up
              </span>
            </Link>
          </p>
        </div>
        <div className="flex justify-center mt-4">
            {/* Wrap GoogleLogin component within GoogleOAuthProvider */}
            <GoogleOAuthProvider clientId="316530854762-kb145t759dpj7tbptr3lq4ljgjbroees.apps.googleusercontent.com">
              <GoogleLogin
                disabled={loading}
                type="button"
                className="btn max-w-52 flex items-center h-10 rounded-md bg-logo-purple hover:bg-gray-100 px-2"
                onSuccess={googleLoginSuccess}
                onError={googleLoginError}
              >
                <FontAwesomeIcon
                  icon={faGoogle}
                  className="border-0 bg-transparent -mr-3 shadow-none bg text-white"
                />
                Sign in with Google
              </GoogleLogin>
            </GoogleOAuthProvider>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
