import React, { useState } from "react";
import { axiosFetch } from "../../utils";
import axios from "axios";


const initialProficiencies = {
  Read: false,
  Write: false,
  Speak: false,
  Understand: false,
};

const PersonalInfo = (profileData) => {
  
  const [selectedSection, setSelectedSection] = useState("");
  const [personalInfo, setPersonalInfo] = useState({
    fullName: "",
    displayName: "",
    profilePic: "",
    description: "",
    languages: [{ language: "", proficiency: [] }],
  });
  const [proficiencies, setProficiencies] = useState(initialProficiencies);

  const handleProficiencyChange = (e, index) => {
    const { name, checked } = e.target;
    const newLanguages = [...personalInfo.languages];
    newLanguages[index].proficiency = {
      ...newLanguages[index].proficiency,
      [name]: checked,
    };
    setPersonalInfo((prevPersonalInfo) => ({
      ...prevPersonalInfo,
      languages: newLanguages,
    }));
  };
  const [data, setData] = useState({});

  const handleChange = (e, index) => {
    const { name, value, files } = e.target;
    if (name === "profilePic") {
      setPersonalInfo((prevPersonalInfo) => ({
        ...prevPersonalInfo,
        [name]: files[0],
      }));
    } else {
      setPersonalInfo((prevPersonalInfo) => ({
        ...prevPersonalInfo,
        languages: prevPersonalInfo.languages.map((language, i) =>
          i === index ? { ...language, [name]: value } : language
        ),
      }));
    }
  };
  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
    try {
      const _id = localStorage.getItem("id");
  
      const requestData = {
        section: "personalInfo",
        data: {
          firstName: data.firstName,
          Displayname: data.displayName,
          profilepic: data.profilePic,
          description: data.description,
          language: data.language,
          proficiency: data.proficiency,
        },
      };
  
      const response = await axios.put(`api/profile/update/`+_id, requestData);
      console.log(response.data);
    } catch (error) {
      console.error("Error updating personal info:", error);
    }
  };



  const handleAddLanguage = () => {
    setPersonalInfo((prevPersonalInfo) => ({
      ...prevPersonalInfo,
      languages: [
        ...prevPersonalInfo.languages,
        { language: "", proficiency: {} },
      ],
    }));
  };

  return (
    <div className="flex justify-center items-center h-auto m-10   ">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl text-logo-blue mb-4">Personal Info</h2>
        {/* Full Name */}
        <div className="mb-4">
          <label htmlFor="fullName" className="text-logo-blue mb-2 block">
            Full Name*
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
            placeholder="Full Name"
            value={profileData.profileData.name}
            onChange={(e) => handleChange(e, 0)}
          />
        </div>
        {/* Display Name */}
        <div className="mb-4">
          <label htmlFor="displayName" className="text-logo-blue mb-2 block">
            Display Name (Secret)*
          </label>
          <input
            type="text"
            id="displayName"
            name="displayName"
            className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
            placeholder="Display Name"
            value={personalInfo.displayName}
            onChange={(e) => handleChange(e, 0)}
          />
        </div>
        {/* Profile Pic */}
        <div className="mb-4">
          <label htmlFor="profilePic" className="text-logo-blue mb-2 block">
            Profile Pic*
          </label>
          <input
            type="file"
            id="profilePic"
            name="profilePic"
            className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
            accept="image/*"
            onChange={handleChange}
          />
        </div>
        {/* Description */}
        <div className="mb-4">
          <label htmlFor="description" className="text-logo-blue mb-2 block">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
            placeholder="Description"
            value={personalInfo.description}
            onChange={handleChange}
          />
        </div>
        {/* Languages */}
        {personalInfo.languages.map((language, index) => (
          <div key={index} className="mb-4 flex">
            <div className="flex-1 mr-2">
              <label
                htmlFor={`language${index}`}
                className="text-logo-blue mb-2 block"
              >
                Language*
              </label>
              <input
                type="text"
                id={`language${index}`}
                name="language"
                className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1 w-full"
                placeholder="Language"
                value={language.language}
                onChange={(e) => handleChange(e, index)}
              />
            </div>
            <div className="flex-1 ml-2">
              <label className="text-logo-blue mb-2 block">Proficiency*</label>
              {Object.entries(proficiencies).map(([proficiency, checked]) => (
                <div key={proficiency} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`${proficiency}-${index}`}
                    name={proficiency}
                    checked={language.proficiency[proficiency] || false}
                    onChange={(e) => handleProficiencyChange(e, index)}
                    className="mr-2"
                  />
                  <label htmlFor={`${proficiency}-${index}`}>
                    {proficiency}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="flex justify-center">
          <button
            className="p-2 text-white rounded-lg bg-logo-blue"
            onClick={handleAddLanguage}
          >
            + Add Language
          </button>
        </div>
        <div className="flex justify-center mt-5">
          <button onClick={handlePersonalInfoSubmit} className="p-2 text-white rounded-lg bg-logo-blue">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
