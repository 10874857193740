import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./GigCard.scss";
import star from "../../assets/star.jpeg";
import thumbsup from "../../assets/thumbsup.jpeg";
import noavatar from "../../assets/noavatar.png";
import fldefault from "../../assets/freelancerdefault.png";

const GigCard = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState(noavatar);
  const [gigCoverImage, setGigCoverImage] = useState(fldefault);

  useEffect(() => {
    const loadImages = async () => {
      const userProfilePath = data.userID.image;
      const gigCoverPath = data.cover;

      try {
        // Load user profile image
        if (userProfilePath) {
          const userProfileImg = new Image();
          userProfileImg.src = userProfilePath;
          userProfileImg.onerror = () => setUserProfileImage(noavatar);
          userProfileImg.onload = () => setUserProfileImage(userProfilePath);
        }

        // Load gig cover image
        if (gigCoverPath) {
          const gigCoverImg = new Image();
          gigCoverImg.src = gigCoverPath;
          gigCoverImg.onerror = () => setGigCoverImage(fldefault);
          gigCoverImg.onload = () => setGigCoverImage(gigCoverPath);
        }
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    loadImages();
  }, [data]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleGetQuoteClick = () => {
    openModal();
  };
  return (
    <Link to={`/gig/${data._id}`} className="link">
      <div className="gigCard">
        <img src={gigCoverImage} alt="" />
        <div className="info">
          <div className="user">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYoCpYbnYiPFSJAw9ozabbMr7fucuIqlNLmDCUFxi0ZU8WFceA2hx28kZncPI2ZplvNx0&usqp=CAU"
              alt=""
            />
            <span>{data.userID.username}</span>
          </div>
          <p>{data.title}</p>
          <div className="star">
            <img src={star} alt="" />
            <span>{Math.round(data.totalStars / data.starNumber) || 0}</span>
            <span className="totalStars">({data.starNumber})</span>
          </div>
        </div>
        <div className=" space-x-4 text-white flex justify-center p-4">
          <Link to="/messages">
            <button className="bg-logo-blue p-2 rounded-lg">Chat</button>
          </Link>
          <button
            className="bg-logo-blue p-2 rounded-lg"
            onClick={handleGetQuoteClick}
          >
            Get Quote
          </button>{" "}
          <div className="bg-logo-blue p-2 rounded-lg">Show More</div>
        </div>
        <hr />
        <div className="detail">
          <img src={thumbsup} alt="" />
          <div className="price">
            <span>STARTING AT</span>
            <h2>
              {data.price.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: "INR",
              })}
            </h2>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default GigCard;
