import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faHandshake,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";

import "../WhyResearchers/WhyResearchers.scss";

import bgresearch from "../../assets/bg-research.jpg";

const WhyResearchers = () => {
  const isMobile = window.innerWidth <= 768; // Define mobile breakpoint as <= 768px

  return (
    <div className="flex justify-center md:mt-32 mt-32">
      <div
        className="featured flex justify-center items-center rounded-xl m-4 md:m-8 text-black flex-wrap md:flex-nowrap mobile"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          width: "60%",
          margin: isMobile ? "10px" : "0",
          backgroundImage: isMobile ? "none" : `url(${bgresearch})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: isMobile ? "#ccc" : "transparent",
        }}
      >
        <div className="rounded-xl flex flex-col justify-center px-4 py-6 mb-4 md:mb-0 w-full md:w-2/3 lg:w-full">
          <h1 className="text-center text-logo-blue text-3xl md:text-4xl mb-6">
            Why you should choose Assign Over?
          </h1>
          <div className="mt-6">
            <div className="flex items-start mb-4">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-black bg-transparent border-0 mr-4 mt-8 shadow-none"
              />
              <div>
                <h2 className="text-lg md:text-xl text-logo-purple mb-2">
                  Assurance
                </h2>
                <p className="mb-4">
                  We ensure quality with audits & revisions as per the chosen
                  payment plan.
                </p>
              </div>
            </div>
            <div className="flex items-start mb-4">
              <FontAwesomeIcon
                icon={faHandshake}
                className="text-black bg-transparent border-0 mr-4 mt-8 shadow-none"
              />
              <div>
                <h2 className="text-lg md:text-xl text-logo-purple mb-2">
                  Reliability
                </h2>
                <p className="mb-4">
                  The experts are carefully chosen based on their previous work
                  experience in the same domain.
                </p>
              </div>
            </div>
            <div className="flex items-start">
              <FontAwesomeIcon
                icon={faCommentAlt}
                className="text-black bg-transparent border-0 mr-4 mt-8 shadow-none"
              />
              <div>
                <h2 className="text-lg md:text-xl text-logo-purple mb-2">
                  Responsiveness
                </h2>
                <p>
                  The experts are just one message away from you for any
                  concern. In case not satisfied, you can connect to the “Assign
                  over Support”.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyResearchers;
