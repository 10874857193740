import React from "react";
import TrustedBy from "../components/TrustedBy";
import Featured from "../components/Featured/Featured";
import Slide from "../components/Slide/Slide";
import CategoryCard from "../components/CategoryCard/CategoryCard";
import WhyUs from "../components/WhyUs/WhyUs";
import Blogs from "../components/Blogs/Blogs";
import WhyResearchers from "../components/WhyResearchers/WhyResearchers";
import LE from "../assets/LabExperiments.jpg";
import SM from "../assets/StatisticalModal.jpg";
import RW from "../assets/ResearchWriting.jpg";
import PD from "../assets/ProductDevelopment.jpg";

const Home = () => {
  const cards = [
    {
      id: 1,
      title: "Lab Experiments",
      img: LE,
      slug: "data-analysis",
    },
    {
      id: 2,
      title: "Statistical Model",
      img: SM,
      slug: "statistical-modeling",
    },
    {
      id: 3,
      title: "Research Writing",
      img: RW,
      slug: "research-writing",
    },
    {
      id: 4,
      title: "Product Development",
      img: PD,
      slug: "experiment-design",
    },
  ];

  return (
    <div>
      <Featured />
      <TrustedBy />
      <Slide slidesToShow={4}>
        {cards.map((card) => (
          <CategoryCard key={card.id} data={card} />
        ))}
      </Slide>
      <WhyUs />
      <WhyResearchers />
      <Blogs />
    </div>
  );
};

export default Home;
