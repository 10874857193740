import { Link } from "react-router-dom";
import "./CategoryCard.scss";

const Card = (props) => {
  const { data } = props;

  return (
    <div className="max-w-[100vw] overflow-hidden">
      <Link to={`/gigs?category=${data.slug}`}>
        <div className="cardContainer">
          <img src={data.img} alt={data.title} />
          <span className="title">{data.title}</span>
          {/* <span className="desc">{data.desc}</span> */}
        </div>
      </Link>
    </div>
  );
};

export default Card;
