import React, { useState } from "react";
import axios from "axios";

const WorkProfile = () => {
  const [data, setData] = useState({});

  const [workItems, setWorkItems] = useState([
    { type: "", title: "", description: "" },
  ]);

  const handleAddWorkItem = () => {
    setWorkItems([...workItems, { type: "", title: "", description: "" }]);
  };

  const handleTypeChange = (index, e) => {
    const updatedItems = [...workItems];
    updatedItems[index].type = e.target.value;
    setWorkItems(updatedItems);
  };

  const handleTitleChange = (index, e) => {
    const updatedItems = [...workItems];
    updatedItems[index].title = e.target.value;
    setWorkItems(updatedItems);
  };

  const handleDescriptionChange = (index, e) => {
    const updatedItems = [...workItems];
    updatedItems[index].description = e.target.value;
    setWorkItems(updatedItems);
  };

  const handleWorkProfileSubmit = async (e) => {
    e.preventDefault();
    try {
      const _id = localStorage.getItem("id");

      // Prepare request data
      const requestData = {
        section: "workProfile",
        data: {
          type:data.type,
          title:data.title,
          description:data.description,
          
        }
        ,
      };

      // Make PUT request to update user profile
      const response = await axios.put(
        `api/profile/update/`+_id,
        requestData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error updating work profile:", error);
    }
  };

  return (
    <div className="flex justify-center items-center h-auto m-10">
      <div className="w-full p-8 bg-white rounded-lg shadow-lg max-w-md">
        <h2 className="text-2xl text-logo-blue mb-4">Work Profile</h2>
        {workItems.map((item, index) => (
          <div
            key={index}
            className="mb-4 border-logo-purple border-2 p-4 rounded-lg"
          >
            <div className="flex flex-col mb-2">
              <label className="text-logo-blue">Type:</label>
              <select
                value={item.type}
                onChange={(e) => handleTypeChange(index, e)}
                className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1"
              >
                <option value="">Choose type of work</option>
                <option value="book">Book</option>
                <option value="bookChapter">Book Chapter</option>
                <option value="conferencePaper">Conference Paper</option>
                <option value="developmentWork">Development Work</option>
                <option value="researchArticle">Research Article</option>
                <option value="researchProject">Research Project</option>
                <option value="thesis">Thesis</option>
              </select>
            </div>
            <div className="flex flex-col mb-2">
              <label className="text-logo-blue">Title:</label>
              <input
                type="text"
                placeholder="Title"
                value={item.title}
                onChange={(e) => handleTitleChange(index, e)}
                className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1"
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="text-logo-blue">Description:</label>
              <textarea
                placeholder="Description"
                value={item.description}
                onChange={(e) => handleDescriptionChange(index, e)}
                className="border-b-2 border-solid border-logo-blue outline-none px-2 py-1"
              />
            </div>
          </div>
        ))}
        <div className="flex justify-center mt-4">
          <button
            onClick={handleAddWorkItem}
            className="bg-logo-blue text-white px-4 py-2 rounded-md mr-2"
          >
            Add Work Item
          </button>
          <button
            onClick={handleWorkProfileSubmit}
            className="bg-logo-blue text-white px-4 py-2 rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkProfile;
