import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import backgroundImage from "../assets/bg-login.jpg";

const ContactUs = () => {
  return (
    <div
      className="bg-cover bg-center min-h-[100vh]"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center justify-center h-full text-black ">
        <h1 className="text-4xl font-bold mb-8 text-logo-purple  mt-40">
          Contact Us
        </h1>
        <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg max-w-md">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faPhone} className="text-blue-500 mr-2" />
            <p>
              <strong>Mob:</strong> +91-7978272488 / 9778516365
            </p>
          </div>
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 mr-2" />
            <p>
              <strong>E-Mail:</strong> support@assignover.com
            </p>
          </div>
          <div className="flex flex-col mb-4">
            <p className="text-lg text-blue-300">ADDRESS</p>
            <p className="text-sm mb-2">
              STEP, Thapar Institute of Engineering and Technology, Patiala,
              Punjab
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold mb-4 text-blue-300">
              FOLLOW US
            </p>
            <div className="flex items-center">
              <a
                href="https://www.linkedin.com/company/assign-over/"
                className="mr-4"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-blue-500 text-2xl"
                />
              </a>
              <a href="https://www.facebook.com/assignover" className="mr-4">
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="text-blue-500 text-2xl"
                />
              </a>
              <a href="https://www.instagram.com/assignover/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-blue-500 text-2xl"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
