import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  useMutation,
  useQuery,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import { QueryClient } from "@tanstack/react-query";
import "./Messages.scss";
import moment from "moment";

const queryClient = new QueryClient();

const Messages = () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser")) || {
    isSeller: false,
  }; // Set a default value if currentUser is null

  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery({
    queryKey: ["conversations"],
    queryFn: () =>
      axios.get(`/api/conversations/`).then((res) => {
        return res.data;
      }),
  });

  console.log(data);
  const mutation = useMutation({
    mutationFn: (_id) => {
      // Retrieve conversationId from localStorage
      const conversationId = localStorage.getItem("_conversationId");

      // Ensure conversationId is available
      if (!conversationId) {
        throw new Error("Conversation ID not found in localStorage");
      }

      // Make the mutation request with conversationId
      return axios.put(`/api/conversations/${_id}`, { conversationId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["conversations"]);
    },
  });

  const handleRead = (id) => {
    mutation.mutate(id);
  };

  return (
    <div className="messages">
      {isLoading ? (
        "loading"
      ) : error ? (
        "error"
      ) : (
        <div className="container">
          <div className="title ">
            <h1 className="text-logo-blue font-bold text-3xl">Messages</h1>
          </div>
          <table>
            <thead>
              <tr className="">
                <th>{currentUser.isSeller ? "Buyer" : "Seller"}</th>
                <th>Last Message</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((c) => (
                <tr
                  className={
                    ((currentUser.isSeller && !c.readBySeller) ||
                      (!currentUser.isSeller && !c.readByBuyer)) &&
                    "active"
                  }
                  key={c.id}
                >
                  <td>{currentUser.isSeller ? c.buyerId : c.sellerId}</td>
                  <td>
                    <Link to={`/message/${c._id}`} className="link">
                      {c?.lastMessage?.substring(0, 100)}...
                    </Link>
                  </td>
                  <td>{moment(c.updatedAt).fromNow()}</td>
                  <td>
                    {((currentUser.isSeller && !c.readBySeller) ||
                      (!currentUser.isSeller && !c.readByBuyer)) && (
                      <button onClick={() => handleRead(c._id)}>
                        Mark as Read
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Messages />
  </QueryClientProvider>
);

export default App;
